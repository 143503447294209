<template>
  <div>
    <NavigationBar
      titel="Abrechnungsart anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="createItem"
        ref="form"
        v-model="helpers.formIsValid"
      >
        <payroll-type-editor
          v-model="payrollType"
          mode="new"
        ></payroll-type-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { AVAILABILITY } from "@/store/modules.js";
import { CREATE_BILLING_TYPE } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import PayrollTypeEditor from "@/components/accounting/PayrollTypeEditor.vue";

export default {
  name: "accounting-config-tab-payroll-types-payroll-type-new",
  components: {
    NavigationBar,
    PayrollTypeEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],
      payrollType: {
        title: "",
        shortTitle: "",
        description: "",
        color: "#F44336",
        sortKey: Number,

        // to calculate eligble time:
        timeRoundingType: "", // "none", "roundUp", "roundDown", "roundToNearest", "roundToNearestHalf", "roundToNearestQuarter", "roundToNearestTenth", "roundToNearestTwentieth", "roundToNearestHalfHour", "roundToNearestQuarterHour", "roundToNearestTenthHour", "roundToNearestTwentiethHour"
        // to calculate wage:
        formula: "", // basic calculations to also use with available placeholders: {hours}, {wage}, {time}
        // to calculate due date of payment:
        paymentOfWagesType: "", // "daily", "weekly", "biweekly", "monthly", "quarterly", "yearly"
        quota: {
          // if applicable, payroll type is only x time units in period specified
          // (e.g. 1x per month, 2x per week, 4x per year):
          isApplicable: false,
          // timeUnit: "", // "day", "week", "month", "quarter", "year"
          // allowedMax: Number,
        },
        // only valid in this date range:
        validFrom: { date: null, time: null, timestamp: null },
        validTo: { date: null, time: null, timestamp: null },
        // needs approval; if yes, who can approve:
        needsApproval: false,
        approvalBy: "",
        // needs confirmation; if yes, who can confirm:
        needsConfirmation: false,
        confirmationBy: "",
        isDefault: false,
        status: "active", // "active", "disabled", "archived"
      },
      helpers: {
        formIsValid: false,
      },
    };
  },
  computed: {
    newSortKey() {
      return this.$store.state.accounting.payrollTypes.length + 1;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.payrollType.sortKey = this.newSortKey;
    },
    validate() {
      this.$refs.form.validate();
    },
    createItem() {
      this.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${AVAILABILITY}${CREATE_BILLING_TYPE}`, {
          organizationId: this.$route.params.organizationId,

          // INSERT DATA
          sortKey: this.payrollType.sortKey,
        });
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>

<style></style>
