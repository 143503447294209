<template>
  <div>
    <template>
      <v-container>
        <v-row>
          <v-col v-if="mode === 'edit'" cols="12">
            <v-alert border="left" colored-border type="info" elevation="2">
              Die Aktualisierung der Abrechnungsart beeinflusst alle gemeldeten
              Stunden, die diese Abrechnungsart verwenden und noch nicht
              abgerechnet (und damit festgeschrieben) wurden.
            </v-alert>
          </v-col>
          <v-col cols="12">
            <Subheader
              icon="mdi-format-list-bulleted-type"
              title="Allgemein"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="5">
                        <v-text-field
                          v-model.trim="payrollTypeLocal.title"
                          :counter="32"
                          label="Bezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model.trim="payrollTypeLocal.shortTitle"
                          :counter="6"
                          label="Kurzbezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="3">
                        <v-text-field
                          v-model.number="payrollTypeLocal.sortKey"
                          label="Sortierschlüssel"
                          prepend-inner-icon="mdi-sort-numeric-ascending"
                          type="number"
                          hint="Abfragearten werden aufsteigend nach ihrem Sortierschlüssel sortiert."
                          persistent-hint
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model.trim="payrollTypeLocal.description"
                          clearable
                          hide-details="auto"
                          no-resize
                          :rows="3"
                          label="Interne Verwendungshinweise"
                          outlined
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        Farbe wählen
                        <v-color-picker
                          v-model="payrollTypeLocal.color"
                          :swatches="availableColors"
                          hide-inputs
                          hide-canvas
                          hide-sliders
                          show-swatches
                          width="1200"
                          swatches-max-height="150"
                        ></v-color-picker>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader icon="mdi-calculator" title="Berechnung"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="4" sm="6">
                        <v-select
                          v-model="payrollTypeLocal.timeRoundingType"
                          :items="timeRoundingTypes"
                          prepend-inner-icon="mdi-counter"
                          :rules="[rules.required]"
                          label="Zeit runden auf"
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <div class="mb-4">
                          Platzhalter hinzufügen:
                          <v-btn
                            v-for="(placeholder, index) in formulaPlaceholders"
                            :key="index"
                            @click="addPlaceholder(placeholder)"
                            small
                            depressed
                            class="mr-2 mb-2"
                          >
                            <pre>{{ placeholder }}</pre>
                          </v-btn>
                        </div>
                        <v-textarea
                          v-model="payrollTypeLocal.formula"
                          outlined
                          :rows="1"
                          auto-grow
                          label="Formel"
                          clearable
                          :rules="[rules.required]"
                          prepend-inner-icon="mdi-equal"
                        ></v-textarea>
                        <v-card outlined>
                          <v-card-title>
                            <v-btn
                              depressed
                              @click="validateFormula"
                              :loading="helpers.validatingFormula"
                              small
                              class="mr-2"
                              ><v-icon left>mdi-check-decagram</v-icon>Formel
                              validieren</v-btn
                            >
                            <v-btn
                              depressed
                              @click="toggleFormulaTestDialog"
                              small
                              ><v-icon left>mdi-play-circle</v-icon>Formel
                              testen</v-btn
                            >
                            <v-spacer></v-spacer>
                            <v-chip color="error" class="mr-2" small
                              >Nicht validiert</v-chip
                            >
                            <v-chip color="error" small>Nicht getestet</v-chip>
                          </v-card-title>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12">
            <Subheader icon="mdi-cog" title="Einstellungen"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="payrollTypeLocal.paymentOfWagesType"
                          :items="paymentOfWagesTypes"
                          prepend-inner-icon="mdi-cash-clock"
                          :rules="[rules.required]"
                          label="Auszahlungsrhythmus"
                          hint="Zur automatischen Berechnung der Fälligkeit anhand des Endzeitpunkts einer Stundenmeldung."
                          persistent-hint
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row class="mb-4">
                      <v-col cols="12">
                        <v-checkbox
                          v-model="payrollTypeLocal.quota.isApplicable"
                          label="Kontingentwarnung aktivieren"
                          hint="Erzeugt eine Warnung bei Überschreiten eines festgelegten Werts."
                          persistent-hint
                          disabled
                        ></v-checkbox>
                      </v-col>
                      <v-col
                        v-if="payrollTypeLocal.quota.isApplicable"
                        cols="12"
                        md="4"
                        sm="6"
                      >
                        <!-- Betrachtungszeitraum -->
                      </v-col>
                      <v-col
                        v-if="payrollTypeLocal.quota.isApplicable"
                        cols="12"
                        md="4"
                        sm="6"
                      >
                        <v-select
                          v-model="payrollTypeLocal.quota.timeUnit"
                          :items="quotaTimeUnits"
                          prepend-inner-icon="mdi-progress-clock"
                          :rules="[rules.required]"
                          label="Zeiteinheit"
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col
                        v-if="payrollTypeLocal.quota.isApplicable"
                        cols="12"
                        md="4"
                        sm="6"
                      >
                        <v-text-field
                          v-model.number="payrollTypeLocal.quota.amount"
                          label="Maximale Anzahl"
                          hint="Maximale Anzahl, die in der festgelegten Zeiteinheit erlaubt sind."
                          persistent-hint
                          type="number"
                          outlined
                        ></v-text-field
                      ></v-col>
                    </v-row>
                    <v-row>Verwendung zulässig ab:</v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="payrollTypeLocal.validFrom.date"
                          type="date"
                          label="Startdatum"
                          hint="DD.MM.YYYY"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          required
                          prepend-inner-icon="mdi-calendar-start"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="payrollTypeLocal.validFrom.time"
                          label="Startzeit"
                          hint="HH:MM"
                          :rules="[rules.required, rules.startBeforeEnd]"
                          outlined
                          type="time"
                          prepend-inner-icon="mdi-clock-start"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>Verwendung zulässig bis:</v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="payrollTypeLocal.validTo.date"
                          type="date"
                          label="Enddatum"
                          hint="DD.MM.YYYY"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          required
                          prepend-inner-icon="mdi-calendar-end"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="payrollTypeLocal.validTo.time"
                          label="Endzeit"
                          hint="HH:MM"
                          outlined
                          :rules="[rules.required, rules.startBeforeEnd]"
                          type="time"
                          prepend-inner-icon="mdi-clock-end"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <custom-dialog
      v-if="helpers.formulaTestDialog.model"
      @close="toggleFormulaTestDialog()"
      title="Formel testen"
      width="m"
      :errorMessage="helpers.formulaTestDialog.errorMessage"
      :btnAction="testFormula"
      :btnDisabled="!helpers.formulaTestDialog.formIsValid"
      :loading="helpers.formulaTestDialog.loading"
      btnText="Ausführen"
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.formulaTestDialog.formIsValid"
          @submit.prevent="testFormula()"
        >
          <v-card outlined class="mt-2">
            <v-card-title class="body-2 v-card__subtitle"
              ><v-icon left small class="mr-2">mdi-variable-box</v-icon
              >Variablen</v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="YEARS"
                    outlined
                    required
                    hide-details="auto"
                    dense
                    prepend-inner-icon="mdi-variable"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="MONTHS"
                    outlined
                    required
                    hide-details="auto"
                    dense
                    prepend-inner-icon="mdi-variable"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="DAYS"
                    outlined
                    required
                    hide-details="auto"
                    dense
                    prepend-inner-icon="mdi-variable"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="HOURS"
                    outlined
                    required
                    hide-details="auto"
                    dense
                    prepend-inner-icon="mdi-variable"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    label="MINUTES"
                    outlined
                    required
                    hide-details="auto"
                    dense
                    prepend-inner-icon="mdi-variable"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title class="body-2 v-card__subtitle"
              ><v-icon left small class="mr-2">mdi-variable-box</v-icon
              >Formel</v-card-title
            >
            <v-card-text>
              <code v-text="'8 * 3,50'"></code>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-title class="body-2 v-card__subtitle"
              ><v-icon left small class="mr-2">mdi-variable-box</v-icon
              >Ausgabe</v-card-title
            >
            <v-card-text>
              <code v-text="'= 28,00'"></code>
            </v-card-text>
          </v-card>
        </v-form>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
export default {
  name: "request-type-editor",
  props: {
    payrollType: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
    CustomDialog,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      helpers: {
        formulaTestDialog: {
          variables: {},
          output: null,
          model: false,
          loading: false,
          errorMessage: "",
        },
      },
      formulaPlaceholders: [
        "{{ YEARS }}",
        "{{ MONTHS }}",
        "{{ DAYS }}",
        "{{ HOURS }}",
        "{{ MINUTES }}",
      ],
      quotaTimeUnits: [
        { value: "days", text: "Tage" },
        { value: "weeks", text: "Wochen" },
        { value: "months", text: "Monate" },
        { value: "years", text: "Jahre" },
      ],
      timeRoundingTypes: [
        { value: "none", text: "Keine", description: "09:41" },
        { value: "roundUp", text: "Aufrunden", description: "" },
        { value: "roundDown", text: "Abrunden", description: "" },
        { value: "roundToNearest", text: "Auf-/Abrunden", description: "" },

        {
          value: "roundToNearestQuarter",
          text: "Auf-/Abrunden auf viertel Stunde",
          description: "",
        },
        {
          value: "roundUpToNearestQuarter",
          text: "Aufrunden auf viertel Stunde",
          description: "",
        },
        {
          value: "roundDownToNearestQuarter",
          text: "Abrunden auf viertel Stunde",
          description: "",
        },

        {
          value: "roundToNearestHalfHour",
          text: "Auf-/Abrunden auf halbe Stunde",
          description: "",
        },
        {
          value: "roundUpToNearestHalfHour",
          text: "Aufrunden auf halbe Stunde",
          description: "",
        },
        {
          value: "roundDownToNearestHalfHour",
          text: "Abrunden auf halbe Stunde",
          description: "",
        },

        {
          value: "roundToNearestHalfHour",
          text: "Auf-/Abrunden auf halbe Stunde",
          description: "",
        },
        {
          value: "roundUpToNearestHalfHour",
          text: "Aufrunden auf halbe Stunde",
          description: "",
        },
        {
          value: "roundDownToNearestHalfHour",
          text: "Abrunden auf halbe Stunde",
          description: "",
        },

        {
          value: "roundToNearestHour",
          text: "Auf-/Abrunden auf volle Stunde",
          description: "",
        },
        {
          value: "roundUpToNearestHour",
          text: "Aufrunden auf volle Stunde",
          description: "",
        },
        {
          value: "roundDownToNearestHour",
          text: "Abrunden auf volle Stunde",
          description: "",
        },
      ],
      paymentOfWagesTypes: [
        { value: "daily", text: "Täglich" },
        { value: "weekly", text: "Wöchentlich" },
        { value: "biweekly", text: "14-tägig" },
        { value: "monthly", text: "Monatlich" },
        { value: "quarterly", text: "Quartalsweise" },
        { value: "yearly", text: "Jährlich" },
      ],
      availableColors: [
        ["#F44336"],
        ["#E91E63"],
        ["#9C27B0"],
        ["#673AB7"],
        ["#3F51B5"],
        ["#2196F3"],
        ["#03A9F4"],
        ["#00BCD4"],
        ["#009688"],
        ["#4CAF50"],
        ["#8BC34A"],
        ["#CDDC39"],
        ["#FFEB3B"],
        ["#FFC107"],
        ["#FF9800"],
        ["#FF5722"],
        ["#795548"],
        ["#607D8B"],
        ["#9E9E9E"],
      ],
    };
  },
  model: {
    prop: "payrollType",
    event: "payrollTypeChange",
  },
  computed: {
    payrollTypeLocal: {
      get: function () {
        return this.payrollType;
      },
      set: function (value) {
        this.$emit("payrollTypeChange", value);
      },
    },
  },
  methods: {
    addPlaceholder(placeholder) {
      if (!this.payrollTypeLocal.formula) {
        this.payrollTypeLocal.formula = "";
      }
      this.payrollTypeLocal.formula += placeholder;
    },
    validateFormula() {},
    toggleFormulaTestDialog() {
      this.helpers.formulaTestDialog.model =
        !this.helpers.formulaTestDialog.model;
    },
    testFormula() {},
  },
};
</script>
